#about .about1 {
  background-color: #010C02;
}

#about .about1 .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

#about .about1 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: white;
  width: 100%;
}

#about .about1 .container .left .gallery {
  display: flex;
  flex-direction: column;
}

#about .about1 .container .left .gallery li {
  display: flex;
  align-items: center;
  gap: .5rem;
}

#about .about1 .container .left .gallery li figure {
  width: 1%;
}

#about .about1 .container .right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  color: white;
}

#about .about1 .container .right .text b {
  color: #1AED31;
}

#about .about1 .container .right .gallery {
  display: flex;
  gap: 1rem;
}

#about .about1 .container .right .gallery .item {
  border: 1px solid white;
  padding: .6rem 1.7rem;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
}

#about .about1 .container .right #ctaWpp {
  display: none;
}

#about .about2 {
  background-color: #1AED31;
}

#about .about2 .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

#about .about2 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

#about .about2 .container .left .title {
  color: black;
  font-size: 30px;
}

#about .about2 .container .mobile {
  display: none;
}

#about .about2 .container .left #ctaWpp {
  background: black;
  width: 55%;
}

#about .about2 .container .left #ctaWpp figure {
  width: 14%;
}

#about .about2 .container .left #ctaWpp a {
  color: white;
}

#about .about3 {
  background-color: #F3F4F5;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-top: 1px solid black;
}

#about .about3 .container .title {
  color: black;
  font-size: 30px;
  text-align: center;
}

#about .about3 .container .title span {
  color: #1AED31;
}

#about .about3 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
}

#about .about3 .container .gallery .item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about3 .container .gallery .item .top {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#about .about3 .container .gallery .item .top figure {
  width: 10%;
}

#about .about3 .container .gallery .item .top p {
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
}

#about .about3 .container .gallery .item .bottom {
  display: flex;
  align-items: center;
  gap: .5rem;
}

#about .about3 .container .gallery .item .bottom figure {
  width: 4%;
  margin-top: .3rem;
}

#about .about3 .container .gallery .item .bottom p {
  font-size: .9rem;
}

#about .about3 .container #ctaWpp {
  margin: 0 auto;
}

#about .about4 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about4 .container .title {
  color: black;
  text-align: center;
  font-size: 30px;
}

#about .about4 .container figure img {
  width: 70%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container {
    flex-direction: column;
    gap: 3rem;
  }

  #about .about1 .container .text.mobile {
    text-align: center;
  }

  #about .about1 .container .left .gallery li figure {
    width: 2%;
  }

  #about .about1 .container .left #ctaWpp {
    display: none;
  }

  #about .about1 .container .right figure img {
    width: 90%;
  }

  #about .about1 .container .right .gallery {
    justify-content: center;
    gap: 2rem;
  }

  #about .about1 .container .right #ctaWpp {
    display: block;
    margin-top: 1rem;
  }

  #about .about2 .container {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  #about .about2 .container .mobile {
    display: block;
    margin: 0 auto;
  }

  #about .about2 .container .left #ctaWpp {
    width: 45%;
  }

  #about .about2 .container .left #ctaWpp figure {
    width: 15%;
  }

  #about .about2 .container .desktop {
    display: none;
  }

  #about .about3 .container .gallery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
  }

  #about .about3 .container .gallery .item .top figure {
    width: 7%;
  }

  #about .about3 .container .gallery .item .bottom figure {
    width: 2%;
    margin-top: .2rem;
  }

}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .left .gallery li figure {
    width: 3%;
  }

  #about .about1 .container .left .gallery li p {
    font-size: 16px;
  }

  #about .about1 .container .right {
    text-align: center;
  }

  #about .about1 .container .right .gallery {
    justify-content: center;
    gap: 1rem;
  }

  #about .about1 .container .right .gallery .item {
    padding: .6rem .8rem;
  }

  #about .about2 .container .left #ctaWpp {
    width: 80%;
  }

  #about .about2 .container .left #ctaWpp figure {
    width: 15%;
  }

  #about .about3 .container .title {
    font-size: 24px;
  }

  #about .about3 .container .gallery .item .top .title_item {
    font-size: 16px;
  }

  #about .about3 .container .gallery .item .bottom figure {
    width: 4%;
    margin-top: .1rem;
  }

  #about .about4 .container figure img {
    width: 100%;
  }
}