#menu {
    position: fixed;
    background-color: #010D02;
    width: 100vw;
}

#menu .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#menu .container .left {
    width: 100%;
    text-align: left;
}

#menu .container .left img {
    width: 30%;
}

#menu .container .right {
    display: flex;
    gap: .1rem;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

#menu .container .right figure {
    width: 6%;
    margin-top: .2rem;
}

#menu .container .right .text a {
    color: white;
}


/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    #menu .container .left {
        text-align: center;
    }

    #menu .container .left img {
        width: 35%;
    }

    #menu .container .right {
        justify-content: center;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}