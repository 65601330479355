#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#header .header1 .container {
    height: 100%;
}

#header .header1 .container .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    width: 50%;
    height: 100%;
}

#header .header1 .container .content .title {
    text-transform: initial;
}

#header .header1 .container .content .title span {
    color: white;
}

#header .header1 .container .content .text {
    color: white;
}

#header .header1 .container .content .text span {
    color: #02F11C;
}

#header .header2 {
    background-color: #010C02;
}

#header .header2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-top: 1px solid #5DED1A;
}

#header .header2 .container .title {
    font-size: 24px;
    text-align: center;
}

#header .header2 .container .title span {
    color: white;
}

#header .header2 .container .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 1rem;
    justify-content: center;
}

#header .header2 .container .gallery .item {
    background: url("../../assets/header2.png") no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: 2rem 1rem;
    color: white;
}

#header .header2 .container .gallery .item .left {
    width: 30%;
}

#header .header2 .container .gallery .item .left img {
    width: 100%;
}

#header .header2 .container .gallery .item .right {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .5rem;
}

#header .header2 .container .gallery .item .right .item_title {
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
}

#header .header2 .container .gallery .item .right .text {
    font-weight: 300;
    line-height: 140%;
    font-size: 0.8rem;
}

#header .header2 .container #ctaWpp {
    margin: 0 auto;
}

#header .header3 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .top img {
    width: 35%;
}

#header .header3 .container .gallery {
    border-top: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 1rem;
}

#header .header3 .container .gallery .left {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .gallery .left .title {
    text-align: left;
    font-size: 30px;
}

#header .header3 .container .gallery .left .title span {
    color: black;
}

#header .header3 .container .gallery .left .text span {
    color: #06A716;
    font-weight: bold;
}

#header .header3 .container .gallery .left .mobile {
    display: none;
}

#header .header3 .container .gallery .right {
    width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 100vh;
    }

    #header .header1 .container .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        width: 100%;
        padding-top: 10rem;
    }

    #header .header1 .container .content .text {
        margin-top: auto;
    }

    #header .header1 .container .content .text span {
        color: #02F11C;
    }

    #header .header2 .container .gallery {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        gap: 2rem;
    }

    #header .header2 .container .gallery .item {
        background: url("../../assets/mobile/header2.png") no-repeat center center;
        background-size: cover;
        width: 70%;
        margin: 0 auto;
        gap: 1rem;
    }

    #header .header2 .container .gallery .item .left {
        width: 20%;
    }

    #header .header3 .container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    #header .header3 .container .top img {
        width: 35%;
    }

    #header .header3 .container .gallery {
        flex-direction: column;
    }

    #header .header3 .container .gallery .left {
        width: 100%;
    }

    #header .header3 .container .gallery .left .title {
        text-align: center;
    }

    #header .header3 .container .gallery .left .text {
        text-align: center;
    }

    #header .header3 .container .gallery .left .mobile {
        display: block;
    }

    #header .header3 .container .gallery .left .mobile img {
        width: 80%;
    }

    #header .header3 .container .gallery .right {
        display: none;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container .content {
        padding-top: 10rem;
    }

    #header .header2 .container .gallery .item {
        width: 100%;
    }

    #header .header3 .container .top img {
        width: 50%;
    }

    #header .header3 .container .gallery .left .mobile img {
        width: 95%;
    }

}