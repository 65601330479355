#ctaWpp {
    background: linear-gradient(to left, #5DED1A, #01C216);
    font-weight: 700;
    padding: .5rem 1.5rem;
    border-radius: 10rem;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: #000000;
}

#ctaWpp a figure {
    width: 20%;
}

#ctaWpp a figure img {
    width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp a figure {
        width: 20%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}